import Layout from '../layouts/layout';
import * as styles from './placer.module.css';
import spacer from '../images/spacer-1.svg';

const PlacerPage = () => {
  return (
    <Layout title="Placer femenino">
      <section className={styles.info}>
        <h1>Placer femenino</h1>
        <p>
          Descubre el placer de habitar un cuerpo femenino, y siente el placer
          de vivir. Porque el placer esta en las pequeñas cosas y son las
          pequeñas cosas las que nos ayudan a conectar con la vida y con nuestro
          cuerpo.
        </p>
        <p>
          Nuestro cuerpo es un recipiente sagrado, que esta al servicio de la
          vida y como tal vibra de puro placer por la simple conexión entre
          mente-útero-corazón.
        </p>
        <p>¿Quieres conocer más de tu conexión mente-útero-corazón?</p>
      </section>
      <section className={styles.products}>
        <div className={styles.spacer}>
          <img src={spacer} alt="" />
          <img src={spacer} alt="" />
          <img src={spacer} alt="" />
        </div>
        <h1>Productos</h1>
        <div className={styles.productGrid}>
          <div className={styles.product}>
            <img src={spacer} />
            <h2>Ritual creación y fuerza</h2>
            <p>
              La fuerza y la creatividad te fueron otorgadas por derecho de
              nacimiento, ahora descubramos todo su poder guardado en secreto en
              nuestro cuerpo. <br />
              <br />
              <em>No apto para mujeres en embarazo o sospecha de estarlo.</em>
            </p>
            <div className={styles.price}>
              $170.000 COP<span> + envío</span>
            </div>
            <button>Comprar</button>
          </div>
          <div className={styles.product}>
            <img src={spacer} />
            <h2>Consulta virtual</h2>
            <p>
              Es un espacio seguro de manera virtual donde realizaremos un
              cambio de percepción frente al placer femenino, mirando temas
              como: fertilidad, orgasmo, creatividad, falta de deseo sexual,
              relaciones sexuales, entre otros
            </p>

            <div className={styles.price}>
              $ 170.000 COP<span> por hora</span>
            </div>
            <button>Separa tu espacio</button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PlacerPage;
